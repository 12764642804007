export const images = [
    {picture: "./7.jpg", date: 1799915600000, info: 'Приветствуем Вас, друзья!!  У нас хорошие новости для всех любителей электронной музыки!!! \n' +
            '\n' +
            'SeemSeem и DJ школа Format открывают двери для тех, кто давно мечтал оказаться по другую сторону пульта.\n' +
            'Программа обучения подойдёт тем, кто совсем не обладает ни какими знаниями и тем, кто уже делает первые шаги в диджеинге, но чувствует, что нуждается прокачке своих навыков.\n' +
            'Записаться на собеседование и узнать подробности можно написав нам в telegram @SeemSeemMusic', poster: 4.5},
    {video: "./5.MP4", date: 1723914000000, info: 'Дорогие друзья,  SeemSeem и центр отдыха «Бумеранг» представляет серию летних вечеринок Open Air «Пляж» \n' +
            'Локация: пляж «Бумеранг» - парк отдыха «У моря»: ул. Софийская 15 к4.  Ангар на пляже «Бумеранг»! https://vk.com/bumerang_rk У ангара есть крыша и открытая площадка, так что нам не страшен дождь, мы будем находиться на открытом воздухе и под крышей - одновременно\n' +
            'Многие из вас уже посещали этим летом наши мероприятия и оценили качество звука и в целом прекрасную атмосферу и летний вайб!! \n' +
            'Пятое мероприятие состоится 17 августа, Хэдлайнеры мероприятия Московские гости Dub Pepper https://www.instagram.com/dub_pepper_sound?igsh=ZDlndmp2ZXNkbHBk  и Deflee https://www.instagram.com/deflee_music?igsh=Y3ZwZDk4MDkyM2Ix !При поддержке наших резидентов:\n' +
            '\n' +
            '22-0- Taygeta \n' +
            '0 - 2 - Asia\n' +
            '2 - 4 - Deflee & Dup Pepper (MSC) \n' +
            '4 - 6 - Apris \n' +
            '6 - 8 - Alex Dav\n' +
            '\n' +
            '📆 Дата: 17 августа 2024\n' +
            '⏰ Время: 22:00 - 08:00 \n' +
            '📍 Место: пляж «Бумеранг» - парк отдыха «У Моря»: ул. Софийская 15 к4. \n' +
            ' \n' +
            'Билеты 🎟️ уже в продаже!!! \n' +
            'Первый релиз 🎫 700₽\n' +
            'Второй релиз 🎫 1000₽ \n' +
            'На входе 🎫 1500₽\n' +
            '👉https://vk.com/seemseemnsk\n' +
            '👉 https://t.me/SeemSeemClub', poster: './5.jpg'},
    {video: "./6.mp4", date: 1725037200000, info: 'Дорогие друзья,  SeemSeem и центр отдыха «Бумеранг» представляет серию летних вечеринок Open Air «Пляж» \n' +
            'Локация: пляж «Бумеранг» - парк отдыха «У моря»: ул. Софийская 15 к4.  Ангар на пляже «Бумеранг»! https://vk.com/bumerang_rk У ангара есть крыша и открытая площадка, так что нам не страшен дождь, мы будем находиться на открытом воздухе и под крышей - одновременно\n' +
            'Многие из вас уже посещали этим летом наши мероприятия и оценили качество звука и в целом прекрасную атмосферу и летний вайб!! \n' +
            '\n' +
            'Шестое и заключительное мероприятие под названием "DARK NIGHT 4" этого лета состоится 30 августа - и это будет «Технотуса» соовместная с нашими друзьями «DDA», Хэдлайнер мероприятия:\n' +
            '\n' +
            'PROGroyal - это сольный музыкальный проект зародившийся в Екатеринбурге, который существует с 2007 года. В этом проекте Роман занимается созданием и продюсированием электронной музыки предпочтительно в жанрах: Techno, Minimal Techno, Melodic Techno. На данный момент PROGroyal является участником музыкального коллектива - Overtone Community и резидентом\n' +
            'самого крупного Techno фестиваля России - Механика Fest. Несколько последних релизов\n' +
            'PROGroyal состоялись на таких лейблах как: Alula Tunes, Subios Records, Music4Aliens, Dense Audio.\n' +
            '\n' +
            'При поддержке наших резидентов а так же ярких представителей промо-команд Сибирских регионов, таких как CLYNICA, МОСТ (г. Омск), SIBRAVE (г. Томск)\n' +
            '\n' +
            'GADI\n' +
            'NOPOLYX\n' +
            'LEENAYA b2b GORADS\n' +
            'PLZMA b2b VALD\n' +
            'PROGROYAL (special guest) \n' +
            'VLADI\n' +
            'DJEK b2b SERDZHEY\n' +
            '\n' +
            '📆 Дата: 30 августа 2024\n' +
            '⏰ Время: 22:00 - 08:00 \n' +
            '📍 Место: пляж «Бумеранг» - парк отдыха «У Моря»: ул. Софийская 15 к4. \n' +
            ' \n' +
            'Билеты 🎟️ уже в продаже!!! \n' +
            'EARLY BIRDS 🎫 700₽‼️проданы‼️\n' +
            'Первый релиз 🎫 900₽ \n' +
            'Второй релиз 🎫 1200₽\n' +
            'На входе 🎫 1400₽\n' +
            '👉https://vk.com/seemseemnsk\n' +
            '👉 https://t.me/SeemSeemClub', poster: './6.jpg'},
];
